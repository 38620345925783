import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import Container from "../components/container";
import Columns from "../components/columns";
import SEO from "../components/seo";

export const query = graphql`
  query {
    produkty: file(relativePath: { eq: "listaproduktow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const BazaProduktow = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Baza produktów i usług"
      headline="Zawsze aktualne informacje"
    >
      <SEO
        title="Baza produktów i usług"
        description="Baza wiedzy o produktach i usługach Twojej agencji reklamowej ✅ Zawsze aktualne informacje • Automatyczne uzupełnianie dokumentów handlowych • Zapytaj o bezpłatne demo!"
        keywords={["baza produktów"]}
      />

      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <p>
                Empireo stanowi dla <strong>agencji reklamowej</strong> również
                swego rodzaju <strong>bazę informacji</strong> - nie tylko o
                kontrahentach, ale także{" "}
                <strong>oferowanych usługach i produktach</strong>W znacznym
                stopniu usprawnia ona{" "}
                <strong>zarządzanie ofertą produktową</strong> oraz sprzedażą.
                Wszyscy pracownicy mają bowiem dostęp do tych samych,{" "}
                <strong>zawsze aktualnych danych</strong> na temat sprzedawanych
                przez firmę usług i produktów.
              </p>
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3>Kluczowe możliwości: </h3>
              <p>
                <ul>
                  <li>
                    <strong>
                      Tworzenie własnych kategorii produktów i usług
                    </strong>
                    , ponieważ oferta agencji reklamowych ciągle ewoluuje i się
                    zmienia.
                  </li>
                  <li>
                    <strong>Generowanie dokumentów handlowych</strong>, dzięki
                    czemu nie ma już potrzeby ręcznego ich uzupełniania.
                  </li>
                  <li>
                    <strong>Cenniki indywidualne i rabaty</strong>, czyli
                    możliwość tworzenia indywidualnych cenników dla wybranych
                    klientów oraz przydzielania im rabatów na wybrane produkty
                    lub usługi.
                  </li>
                  <li>
                    <strong>Wiązanie produktów i usług ze zleceniem</strong>,
                    które ułatwia planowanie pracy zespołu realizacji.
                    Automatyzuje to też proces wystawiania faktur za wykonaną
                    usługę.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text-block">
              <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
                images={[data.produkty]}
                alts={[
                  "Lista produktów i usług w systemie dla agencji reklamowej i marketingowej",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <p>
          Dzięki bazie produktów i usług w Empireo znacznie zwiększysz{" "}
          <strong>spójność komunikacji z klientami</strong> i ograniczysz ilość
          ewentualnych nieporozumień. Twoi pracownicy będą mieli pod ręką{" "}
          <strong>zawsze aktualne i spójne informacje</strong>. Korzystanie z
          bazy produktów przyspieszy także generowanie dokumentów handlowych.
        </p>
      </Container>
    </FunctionPageLayout>
  );
};

export default BazaProduktow;
